import React from 'react'
import { render } from 'react-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import Router from './Router'

// fonts included in index.html

const theme = {
  color: {
    unset: 'unset',
    transparent: 'transparent', // used to remove color from elements that have a default value set

    primary: '#702632',
    success: '#50d643',
    error: '#e61610',
    muted: '#8b8b8b',
    background: '#fafafa',
    backgroundMuted: '#e2e2e2',
    textPrimary: '#242424',
    textSecondary: '#424242',
    label: '#242424',
    input: '#444444',
    dropdownSelectedBackground: '#242424',
    dropdownSelectedText: '#ffffff',
    buttonDisabled: '#b1b1b1',

    descriptionBox: '#242424',
    accent: '#702632',
    accent2: '#242424',
    dark: '#1d1d1d',
    white: '#ffffff',
    outline: '#13020f',

    huzoor: '#059b69',
    namaz: '#089cc9',
    sunday: '#089cc9',
    monday: '#078cb5',
    tuesday: '#067da1',
    wednesday: '#066d8d',
    thursday: '#055e79',
    friday: '#044e65',
    saturday: '#033e50',
    huzoorDarker: '#046444',
    namazDarker: '#077fa3',
  },
  font: {
    size: {
      normal: '1rem',
      small: '0.875rem',
      extraSmall: '0.75rem',
      placeholder: '0.813rem',
      label: '1rem',
      buttonTitle: '1rem',
      buttonTitle2: '0.8rem',
    },
    family: {
      regular: '"Inter", Roboto, sans-serif; font-weight: 400;',
      menuHeader: '"Inter", Roboto, sans-serif; font-weight: 700;',
      buttonTitleFontFamily: '"Inter", Roboto, sans-serif; font-weight: 500;',
      label: '"Inter", Roboto, sans-serif; font-weight: 700;',
      medium: '"Inter", Roboto, sans-serif; font-weight: 500;',
      arabic: '"Noto Naskh Arabic"',
    },
  },
  breakpoints: {
    desktop: '800px',
  },
}

const GlobalStyles = createGlobalStyle`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  .rc-select {
    position: relative;
    margin-bottom: 0.125rem;
  }

  .rc-select input:focus-visible {
    outline: ${(props) => props.theme.color.outline} solid 1px;
  }

  .rc-select .rc-select-selection-search-input {
    appearance: none;
  }
  .rc-select .rc-select-selection-search-input::-webkit-search-cancel-button {
    display: none;
    appearance: none;
  }
  .rc-select-single .rc-select-selector {
    position: relative;
  }
  .rc-select-single .rc-select-selector .rc-select-selection-search {
    width: 100%;
  }
  .rc-select-single .rc-select-selector .rc-select-selection-search-input {
    width: 100%;
  }
  .rc-select-single .rc-select-selector .rc-select-selection-item {
    width: 100%; 
    white-space: nowrap;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    display: flex; align-items: center;
    padding: 0rem 1.4rem 0rem 0.65rem;
    pointer-events: none;
    font-size: ${({ theme }) => theme.font.size.normal};

    > * {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .rc-select-single .rc-select-selector .rc-select-selection-placeholder {
    position: absolute;
    top: 9px;
    left: 0px;
    padding: 0rem 0.65rem;
    pointer-events: none;
    font-size: ${({ theme }) => theme.font.size.placeholder};
    font-style: italic;
    opacity: 0.7;
  }

  .rc-select-single:not(.rc-select-customize-input) .rc-select-selector .rc-select-selection-search-input {
    width: 100%;
  }
 
  .rc-select-allow-clear .rc-select-clear {
    position: absolute;
    right: 24px;
    top: 6px;
  }

  .rc-select-show-arrow .rc-select-arrow {
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 2px;
  }

  .rc-select-show-arrow .rc-select-arrow-icon::after {
    content: '';
    border: 6px solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    border-top: 9px solid ${({ theme }) => theme.color.primary};;
    transform: translateY(10px);
  }

  .rc-select-focused .rc-select-selector {
    border-color: ${({ theme }) => theme.color.primary} !important;
  }

  .rc-select-dropdown {
    border: 1pt solid ${({ theme }) => theme.color.muted};
    min-height: 100px;
    position: absolute;
    background: ${({ theme }) => theme.color.background};
    margin-top: -4px;
    border-radius: 3px;
    overflow: hidden;
    z-index: 100;
  }

  .rc-select-dropdown-hidden {
    display: none;
  }

  .rc-select-item {
    color: ${({ theme }) => theme.color.textPrimary};
  }

  .rc-select-item-option {
    position: relative;
  }

  .rc-select-item-option-active {
    background-color: ${({ theme }) => theme.color.dropdownSelectedBackground};
  }

  .rc-select-item-option-active {
    color: ${({ theme }) => theme.color.dropdownSelectedText};
  }

  .rc-select-item-option-state-icon {
    display: none;
  }

  .rc-select-item-option-disabled {
    color: #999;
  }
  .rc-select-item-empty {
    text-align: center;
    color: #999;
  }

  input.rc-select-selection-search-input {
    border: 1px solid ${({ theme }) => theme.color.muted};
    border-radius: 3px;
    display: block;
    width: 100%;
    padding: 0 0.65rem;
    margin-bottom: 2px;
    background-color: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.textPrimary};
    height: 2rem;
    line-height: 2rem;
    min-width: 130px;
    font-size: ${({ theme }) => theme.font.size.normal};
  }

  .rc-invalid input.rc-select-selection-search-input {
    border: 1px solid ${({ theme }) => theme.color.error};
  }

  input.rc-select-selection-search-input:focus,
  input.rc-select-selection-search-input:focus-within {
    outline: none;
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.color.primary};
    border-width: 0;
    border-bottom-width: 1px;
    border-color: #ffffff00;
    border-bottom-color: ${({ theme }) => theme.color.primary};
  }

  body,input, select, textarea, button {
    font-family: ${({ theme }) => theme.font.family.regular};
  }

  html,body{
    background-color: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.textPrimary};
    margin: 0;
    
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
      background-color: ${({ theme }) => theme.color.backgroundMuted};
    }
  }

  #body {
    min-height: 100vh;
    display: grid;
  }
`

render(
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Router />
  </ThemeProvider>,
  document.getElementById('root')
)
