const BACKEND_URL =
  'https://script.google.com/macros/s/AKfycbyGLj88X4_kg1MXQtXBn2pwF57A5W2T4azH1TtOztQzsX2N5edTzl1eC2ajGLYLnI0E/exec'
export const getKhuddamList = function (setKhuddamList, setNotification) {
  fetch(BACKEND_URL, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify({
      cmd: 'getKhuddamList',
      stupidtest: 'undefined',
    }),
  })
    .then((res) => {
      return res.json()
    })
    .then(
      (e) => {
        if (e.result === 'error') {
          setNotification({ message: 'Failed to fetch khuddam list.', accent: 'error', duration: 7000 })
        } else {
          setKhuddamList(e.khuddam_list)
        }
      },
      () => {
        setNotification({ message: 'Connection issue. Failed to fetch khuddam list.', accent: 'error', duration: 7000 })
      }
    )
}

export const submitWeeklyReport = function (request, setNotification, onSuccess) {
  fetch(BACKEND_URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify(request),
  })
    .then((res) => {
      return res.json()
    })
    .then(
      (e) => {
        if (e.result === 'error') {
          if (e.error === 'AIMS_ID_ALREADY_REGISTERED') {
            setNotification({
              message: 'Ti servi ene lot addresse email pu sa khuddam la. Servi mem email ki dernier fois.',
              accent: 'error',
              duration: 7000,
            })
          } else if (e.error === 'EMAIL_ALREADY_REGISTERED') {
            setNotification({
              message: 'Ena ene lot khuddam ki pe servi sa addresse email la. Servi ene lot addresse email',
              accent: 'error',
              duration: 7000,
            })
          } else {
            setNotification({ message: 'In gagne ene problem kan pe calcule score.', accent: 'error', duration: 7000 })
          }
        } else {
          onSuccess(e.data)
        }
      },
      () => {
        setNotification({ message: 'In gagne ene problem connection.', accent: 'error', duration: 7000 })
      }
    )
}
