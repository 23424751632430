import upd from 'immer'

export default (session, action) => {
  const nextState = upd(session, (draft) => {
    switch (action.type) {
      case 'setKhuddamList':
        draft.khuddam_list = action.khuddam_list
        draft.khuddam_version_list = action.khuddam_version_list
        break
      case 'updateProfile':
        draft.profile = action.profile
        break
      case 'saveDraft':
        draft.profile = action.profile
        draft.draft = action.draft
        break
      case 'setLanguage':
        draft.language = action.language
        break
      case 'saveResult':
        draft.lastReport = action.result
    }
  })
  localStorage.setItem('mka-spiritual-fitness', JSON.stringify(nextState))
  return nextState
}