export const dayList = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
export const namazList = ['fajr', 'zohr', 'asr', 'maghrib', 'isha']
export const namazValueMap = {
  level_1: [
    { value: 'I', label: 'In fer' },
    { value: 'X', label: 'Rater' },
  ],
  level_3: [
    { value: 'I', label: 'In fer' },
    { value: 'B', label: 'Bajamaat' },
    { value: 'X', label: 'Rater' },
  ],
}

export const letterValueList = [
  { value: 'yes', label: 'Oui' },
  { value: 'no', label: 'Non' },
  { value: 'not_applicable', label: 'Fini ekrir 1 sa mois la' },
]
export const yesNoValueList = [
  { value: 'yes', label: 'Oui' },
  { value: 'no', label: 'Non' },
]

export const defaultNamazReports = {
  sunday: { fajr: 'X', zohr: 'X', asr: 'X', maghrib: 'X', isha: 'X' },
  monday: { fajr: 'X', zohr: 'X', asr: 'X', maghrib: 'X', isha: 'X' },
  tuesday: { fajr: 'X', zohr: 'X', asr: 'X', maghrib: 'X', isha: 'X' },
  wednesday: { fajr: 'X', zohr: 'X', asr: 'X', maghrib: 'X', isha: 'X' },
  thursday: { fajr: 'X', zohr: 'X', asr: 'X', maghrib: 'X', isha: 'X' },
  friday: { fajr: 'X', zohr: 'X', asr: 'X', maghrib: 'X', isha: 'X' },
  saturday: { fajr: 'X', zohr: 'X', asr: 'X', maghrib: 'X', isha: 'X' },
}

export const defaultKhilafatReports = {
  letter: 'no',
  khutba: 'no',
  twwh: 'no',
}
export const defaultQuranReports = {
  sunday: 'not_read',
  monday: 'not_read',
  tuesday: 'not_read',
  wednesday: 'not_read',
  thursday: 'not_read',
  friday: 'not_read',
  saturday: 'not_read',
}
export const quranValueList = [
  { value: 'not_read', label: 'Pann lire' },
  { value: 'at_least_1_ruku', label: 'O moins 1 ruku' },
  { value: 'at_least_2_ruku', label: 'O moins 2 ruku' },
  { value: 'at_least_100_verses', label: 'O moin 100 versets' },
  { value: 'at_least_1_part', label: 'O moin 1 parti' },
]
