import dayjs from 'dayjs'
import React, { lazy, Suspense, useReducer, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { getKhuddamList } from './api'
import Load from './assets/sync-alt-solid.svg'
import NotificationContext from './NotificationContext'
import Notifications from './Notifications'
import SessionContext, { initSession } from './SessionContext'
import sessionReducer from './sessionReducer'
import { spin } from './simplified-components/styledElements'

const Home = lazy(() => import('./routes/Home'))
const WeeklyReport = lazy(() => import('./routes/WeeklyReport'))
const Score = lazy(() => import('./routes/Score'))

const Fallback = () => (
  <div css='display: flex; align-items:center; justify-content: center; height: 100vh; '>
    {/* Font Awesome Icon used under https://fontawesome.com/license */}
    <Load
      css={`
        ${spin}
        height: 3rem;
        width: 3rem;
      `}
    />
  </div>
)

/**
 * Shows a notification for 3s (default).
 * @param {string} message - string message to show
 * @param {number} duration - duration in milliseconds
 * @param {string} accent - a color defined in the styled-component's theme.
 */
const setNotification =
  (_setNotifications) =>
  ({ message, accent = 'black', duration = 3000 }) => {
    const id = message + dayjs().format()
    _setNotifications((notifications) => [...notifications, { id, message, accent }])
    setTimeout(() => _setNotifications((notifications) => [...notifications.filter((n) => n.id !== id)]), duration)
  }

const Router = () => {
  let cachedSession = JSON.parse(localStorage.getItem('mka-spiritual-fitness')) || initSession
  if (cachedSession.version != initSession.version) {
    /* reset session if a new version is needed - avoids unstable state. 
        shouldn't cause any issue if we are pulling info from API */
    cachedSession = initSession
    localStorage.setItem('mka-spiritual-fitness', JSON.stringify(cachedSession))
  }

  const [session, dispatch] = useReducer(sessionReducer, cachedSession)
  const [notifications, _setNotifications] = useState([])

  if (
    cachedSession.khuddam_list_version !== initSession.khuddam_list_version ||
    cachedSession.khuddam_list.length === 0
  ) {
    getKhuddamList((l) => {
      l.sort((a, b) => a.other_names.localeCompare(b.other_names)).sort((a, b) => a.surname.localeCompare(b.surname))
      dispatch({
        type: 'setKhuddamList',
        khuddam_list: l,
        khuddam_list_version: initSession.khuddam_list_version,
      })
    }, setNotification(_setNotifications))
  }

  return (
    <NotificationContext.Provider value={{ setNotification: setNotification(_setNotifications) }}>
      <SessionContext.Provider value={{ session, dispatch }}>
        <Notifications notifications={notifications} />
        <BrowserRouter>
          <Routes>
            <Route
              path='/'
              element={
                <Suspense fallback={<Fallback />}>
                  <WeeklyReport />
                </Suspense>
              }
            />
            <Route
              path='/home'
              element={
                <Suspense fallback={<Fallback />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path='/score'
              element={
                <Suspense fallback={<Fallback />}>
                  <Score />
                </Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      </SessionContext.Provider>
    </NotificationContext.Provider>
  )
}

export default Router
