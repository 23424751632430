import { createContext } from 'react'
import { defaultKhilafatReports, defaultNamazReports, defaultQuranReports } from './listsAndMaps'
export const initSession = {
  language: 'Kreol',
  version: 1,
  khuddam_list_version: 1,
  khuddam_list: [],
  profile: {},
  draft: {
    namazReports: defaultNamazReports,
    khilafatReports: defaultKhilafatReports,
    quranReports: defaultQuranReports,
  },
  lastReport: {},
}

const defaultContext = {
  session: null,
  dispatch: () => {},
}

export default createContext(defaultContext)
